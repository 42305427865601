import { graphql, StaticQuery } from 'gatsby';
import React, { FunctionComponent, ReactNode } from 'react';

import Header from './header';
import MainNavigationDesktop from './main-navigation-desktop';

interface Props {
  children: ReactNode;
}

const METADATA_QUERY = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
        recaptcha {
          siteKey
        }
      }
    }
  }
`;

const Layout: FunctionComponent<Props> = ({ children }) => (
  <StaticQuery
    query={METADATA_QUERY}
    render={data => (
      <div className="layout">
        <div className="layout__header">
          <Header siteTitle={data.site.siteMetadata.title} />
        </div>
        <div className="layout__main-navigation">
          <MainNavigationDesktop />
        </div>
        {/* <div className="wrapper">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
            <span className="breadcrumb-separator">&gt;</span>
            <Link to="/contact/">Contact</Link>
          </div>
        </div> */}
        <div className="layout__main-content">
          <main className="main-content">{children}</main>
        </div>
        <footer className="layout__footer footer">
          {/* <div className="line1" />
          <div className="line2" />
          <div className="line3" /> */}
        </footer>
      </div>
    )}
  />
);

export default Layout;
