import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';

import Logo from './logo';
import MainNavigation from './main-navigation';

interface Props {
  siteTitle?: string;
}

const Header: FunctionComponent<Props> = ({ siteTitle }) => (
  <header className="header">
    <Link className="header__home-link" to="/">
      <Logo />
    </Link>
    <Link className="header__site-title" to="/">
      {siteTitle || ''}
    </Link>
    <div className="header__main-navigation">
      <MainNavigation />
    </div>
  </header>
);

export default Header;
