import { Link } from 'gatsby';
import { FunctionComponent, ReactNode } from 'react';
import React from 'react';

interface MainNavigationLinkProps {
  path: string;
  children: ReactNode;
}

const MainNavigationDesktopLink: FunctionComponent<MainNavigationLinkProps> = ({
  path,
  children,
}) => (
  <Link
    className="main-navigation-desktop__link"
    activeClassName="main-navigation-desktop__link--active"
    to={path}
  >
    {children}
  </Link>
);

const MainNavigationDesktop: FunctionComponent = () => (
  <nav>
    <ul className="main-navigation-desktop">
      <li>
        <MainNavigationDesktopLink path="/warmtepompen/">
          Warmtepompen
        </MainNavigationDesktopLink>
      </li>
      <li>
        <MainNavigationDesktopLink path="/airconditioning-en-luchtverwarming">
          Airconditioning en luchtverwarming
        </MainNavigationDesktopLink>
      </li>
      <li>
        <MainNavigationDesktopLink path="/ventilatie/">
          Ventilatie
        </MainNavigationDesktopLink>
      </li>
      <li>
        <MainNavigationDesktopLink path="/condensatieketels/">
          Condensatieketels
        </MainNavigationDesktopLink>
      </li>
      <li>
        <MainNavigationDesktopLink path="/sanitair/">
          Sanitair
        </MainNavigationDesktopLink>
      </li>
      <li>
        <MainNavigationDesktopLink path="/verhuur-van-koelwagens/">
          Koelwagens
        </MainNavigationDesktopLink>
      </li>
      <li>
        <MainNavigationDesktopLink path="/contact/">
          Contact
        </MainNavigationDesktopLink>
      </li>
    </ul>
  </nav>
);

export default MainNavigationDesktop;
