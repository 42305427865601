import { Link } from 'gatsby';
import { FunctionComponent, ReactNode } from 'react';
import React from 'react';

interface MainNavigationLinkProps {
  path: string;
  children: ReactNode;
}

const MainNavigationLink: FunctionComponent<MainNavigationLinkProps> = ({
  path,
  children,
}) => (
  <Link
    className="main-navigation__link"
    activeClassName="main-navigation__link--active"
    to={path}
  >
    {children}
  </Link>
);

const MainNavigation: FunctionComponent = () => (
  <nav className="main-navigation">
    <div className="main-navigation__toggle-button">
      <input
        type="checkbox"
        className="main-navigation__toggle-button__checkbox"
      />

      <span className="hamburger-icon__line" />
      <span className="hamburger-icon__line" />
      <span className="hamburger-icon__line" />

      <ul className="main-navigation__links">
        <li>
          <MainNavigationLink path="/warmtepompen/">
            Warmtepompen
          </MainNavigationLink>
        </li>
        <li>
          <MainNavigationLink path="/airconditioning-en-luchtverwarming">
            Airconditioning en luchtverwarming
          </MainNavigationLink>
        </li>
        <li>
          <MainNavigationLink path="/ventilatie/">
            Ventilatie
          </MainNavigationLink>
        </li>
        <li>
          <MainNavigationLink path="/condensatieketels/">
            Condensatieketels
          </MainNavigationLink>
        </li>
        <li>
          <MainNavigationLink path="/sanitair/">Sanitair</MainNavigationLink>
        </li>
        <li>
          <MainNavigationLink path="/verhuur-van-koelwagens/">
            Koelwagens
          </MainNavigationLink>
        </li>
        <li>
          <MainNavigationLink path="/contact/">Contact</MainNavigationLink>
        </li>
      </ul>
    </div>
  </nav>
);

export default MainNavigation;
